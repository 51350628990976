import React, { useEffect, useRef, useContext } from "react";
import Datafeed from "./datafeed.js";
import "./tvstyle.css";
import { widget } from "../../charting_library";
import MyContext from "../../context/MyContext";
import BuyAndSellModal from "../modals/BuyAndSellModal";

function TVcharts() {
  const chartContainerRef = useRef();
  const {
    chartToken,
    bopen,
    setBopen,
    isToggled,
    setIsToggled,
    setBuyAndSellAction,
    setQty,
    setIsPendingOrder,
    setOrderType,
    setProductType,
  } = useContext(MyContext);

  useEffect(() => {
    const widgetOptions = {
      symbol: chartToken,
      interval: "5",
      fullscreen: true,
      container: "tv_chart_container",
      datafeed: Datafeed,
      timezone: "Asia/Kolkata",
      library_path: "../../charting_library/",
      height: 650,
      time_frames: [
        {
          text: "5y",
          resolution: "D",
        },
        {
          text: "1y",
          resolution: "D",
        },
        {
          text: "6m",
          resolution: "D",
        },
        {
          text: "3m",
          resolution: "D",
        },
        {
          text: "1m",
          resolution: "240",
        },
        {
          text: "5d",
          resolution: "60",
        },
        {
          text: "1d",
          resolution: "5",
        },
      ],
    };

    const tvWidget = new widget(widgetOptions);

    tvWidget.onChartReady(() => {
      tvWidget.headerReady().then(() => {
        const button = tvWidget.createButton();
        button.setAttribute("title", "Click to show a notification popup");
        button.classList.add("apply-common-tooltip");
        button.addEventListener("click", () =>
          tvWidget.showNoticeDialog({
            title: "Notification",
            body: "TradingView Charting Library API works correctly",
            callback: () => {
              // console.log("Noticed!");
            },
          })
        );
        button.innerHTML = "Check API";

        const buyButton = tvWidget.createButton();
        buyButton.setAttribute("title", "Buy");
        buyButton.classList.add("apply-common-tooltip");
        buyButton.addEventListener("click", () => {
          setBuyAndSellAction("buy");
          setIsToggled(false);
          setBopen(true);
          setOrderType("Limit");
          setProductType("Intraday");
          setQty("");
          setIsPendingOrder(false);
        });

        buyButton.style.backgroundColor = "#D4EDDA";
        buyButton.style.color = "#155724";
        buyButton.innerHTML = "Buy";
        buyButton.style.paddingLeft = "37px";
        buyButton.style.paddingRight = "37px";
        buyButton.style.paddingTop = "9px";
        buyButton.style.paddingBottom = "9px";
        buyButton.style.borderRadius = "4px";
        buyButton.style.fontSize = "12px";
        buyButton.style.cursor = "pointer";

        const sellButton = tvWidget.createButton();
        sellButton.setAttribute("title", "Sell");
        sellButton.classList.add("apply-common-tooltip");
        sellButton.addEventListener("click", () => {
          setBuyAndSellAction("sell");
          setIsToggled(true);
          setBopen(true);
          setOrderType("Limit");
          setProductType("Intraday");
          setQty("");
          setIsPendingOrder(false);
        });

        sellButton.innerHTML = "Sell";
        sellButton.style.backgroundColor = "#F8D7DA";
        sellButton.style.color = "#721C24";

        sellButton.style.paddingLeft = "37px";
        sellButton.style.paddingRight = "37px";
        sellButton.style.paddingTop = "9px";
        sellButton.style.paddingBottom = "9px";
        sellButton.style.borderRadius = "4px";
        sellButton.style.fontSize = "12px";
        sellButton.style.cursor = "pointer";
      });
    });

    return () => {
      tvWidget.remove();
    };
  }, [chartToken]);

  return (
    <>
      <div
        ref={chartContainerRef}
        className="tv_chart_container"
        id="tv_chart_container"
      ></div>
      <BuyAndSellModal />
    </>
  );
}

export default TVcharts;
