import React, { useEffect, useContext, useState, Fragment } from "react";
import Layout from "../../components/layout/Layout";
import MyContext from "../../context/MyContext";
import DownloadIcon from "../../assets/icons/download.png";
import selectIcon from "../../assets/icons/select.png";
import deleteIcon from "../../assets/icons/del.png";
import editIcon from "../../assets/icons/editing.png";
import correctIcon from "../../assets/icons/correct.png";
import { Tab } from "@headlessui/react";
import "../../styles/brokerintegration.css";
import Modal from "react-modal";
import Loader from "../../components/loader/Loader";
import upIcon from "../../assets/icons/up.png";
import rupeeIcon from "../../assets/icons/rupee.png";
import downgray from "../../assets/icons/downgray.png";
import { toast } from "react-toastify";
import BuyAndSellModal from "../../components/modals/BuyAndSellModal";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    width: "522px",
    // height: "343px",
    padding: "0px",
    borderRadius: "4px",
  },
  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
  },
};

function ScriptSetting() {
  const {
    setPageName,
    loading,
    setLoading,
    userprofile,
    isToggled,
    setIsToggled,
    bopen,
    setBopen,
    qty,
    setQty,
    scripts,
    setScripts,
    setBuyAndSellInstrumentType,
    setBuyAndSellTradingSymbol,
    setBuyAndSellExchange,
    setBuyAndSellModalLivePrice,
    setBuyAndSellExchangeToken,
    setChartToken,
    onScriptSetting,
    isScriptOrBased,
    setIsScriptOrBased,
    getScriptSettingBasedOn,
    scriptSettingBasedOn,
    setScriptSettingBasedOn, setIsPendingOrder
  } = useContext(MyContext);

  const [trading_symbol, setTrading_Symbol] = useState("");
  const [instrument_type, setInstrument_type] = useState("");
  const [validity, setValidity] = useState("DAY");
  const [selectedOption, setSelectedOption] = useState("");
  const [productType, setProductType] = useState("Intraday");
  const [orderType, setOrderType] = useState("Limit");
  const [isDisabled, setIsDisabled] = useState(false);
  const [exchange, setExchange] = useState("");

  const [isMoreDropdown, setIsMoreDropdown] = useState(false);
  const [token, setToken] = useState("");
  const tabsData = [
    { label: "Regular", value: "regular", desc: "Content for Regular" },
    { label: "Cover", value: "cover", desc: "Content for Cover" },
    { label: "AMO", value: "amo", desc: "Content for AMO" },
  ];

  const [activeTab, setActiveTab] = useState("regular");
  // const [isToggled, setIsToggled] = useState(false);
  const [isBuy, setIsBuy] = useState("Buy");
  const statusClasses = {
    COMPLETED: { bgcolor: "#D4EDDA", textColor: "#155724" },
    REJECTED: { bgcolor: "#F8D7DA", textColor: "#721C24" },
    PENDING: { bgcolor: "#B6E0FF", textColor: "#013052" },
  };

  const statusBgColor = (status) => {
    let bg = "";
    switch (status) {
      case "CNC":
        bg = statusClasses.COMPLETED.bgcolor;
        break;

      case "NRML":
        bg = statusClasses.REJECTED.bgcolor;
        break;

      case "MIS":
        bg = statusClasses.PENDING.bgcolor;
        break;
    }
    return bg;
  };

  const handleOptionChange = (e) => {
    // console.log("selected option : ", e.target.value);
    setSelectedOption(e.target.value);
  };

  const handleToggle = () => {
    if (!isToggled) {
      setIsBuy("Sell");
    } else {
      setIsBuy("Buy");
    }
    setIsToggled(!isToggled);
  };

  const statusTextColor = (status) => {
    let color = "";
    switch (status) {
      case "NSE":
        color = statusClasses.COMPLETED.textColor;
        break;

      case "NRML":
        color = statusClasses.REJECTED.textColor;
        break;

      case "MIS":
        color = statusClasses.PENDING.textColor;
        break;
    }
    return color;
  };

  const onClickBuyAndSell = async () => {
    setLoading(true);
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    const body = {
      token: token,
      trading_symbol: trading_symbol,
      exchange: exchange,
      order_type: orderType,
      product_type: productType,
      qty: qty,
      validity: validity,
      action: isBuy,
    };

    await fetch("https://app.stoxviews.com/api/placeorder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        if (value.status) {
          setLoading(false);
          toast.success(`${isBuy} successfully`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setBopen(false);
          setQty("");
        } else {
          setLoading(false);
          toast.error(`${isBuy} Failed`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setBopen(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(`${isBuy} Failed`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  useEffect(() => {
    setPageName("scriptsetting");
    getScriptSettingBasedOn();
  }, []);

  const onBuyClickOnModal = (position) => {
    // console.log("pso : ", position?.token_id);
    let token_id = position?.token_id;
    setBuyAndSellExchange(token_id?.exchange);
    setBuyAndSellTradingSymbol(token_id?.trading_symbol);
    setBuyAndSellInstrumentType(token_id?.instrument_type);
    setBuyAndSellModalLivePrice(position?.ltp);
    // setBuyAndSellExchangeToken(token_id?.exchange_token);
    setChartToken(token_id?.exchange_token);
    setIsToggled(false);
    setBopen(true);
    setIsPendingOrder(false)
    setQty("");
    setOrderType("Limit");
    setProductType("Intraday");

    // setBuyAndSellExchange(item.exchange);
    // setBuyAndSellTradingSymbol(item.trading_symbol);
    // setBuyAndSellInstrumentType(item.instrument_type);
    // // setBuyAndSellAction(action);
    // setBuyAndSellModalLivePrice(lastPrice);
  };

  const onSellClickOnModal = (position) => {
    //console.log("pso : ", position);
    let token_id = position.token_id;
    setBuyAndSellExchange(token_id?.exchange);
    setBuyAndSellTradingSymbol(token_id?.trading_symbol);
    setBuyAndSellInstrumentType(token_id?.instrument_type);
    setBuyAndSellModalLivePrice(position?.ltp);
    // setBuyAndSellExchangeToken(token_id?.exchange_token);
    setChartToken(token_id?.exchange_token);
    setIsToggled(true);
    setBopen(true);
    setIsPendingOrder(false)
    setQty("");
    setOrderType("Limit");
    setProductType("Intraday");
  };

  const onDeleteScriptItem = async (id) => {
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    const body = {
      symbol_id: id,
    };

    // console.log("body : ", body);

    await fetch("https://app.stoxviews.com/api/deletescrip", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        // console.log("on script item delete value : ", value);
        if (value.status) {
          toast.success(`Script item deleted successfully`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          onScriptSetting();
        } else {
          toast.error(`${isBuy} Failed`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(`${isBuy} Failed`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const selectScriptContract = (contract_num) => {
    // <option value="1">Nearest week contract</option>
    // <option value="2">2nd week contract</option>
    // <option value="3">3rd week contract</option>
    // <option value="4">last month/ last week contract</option>
    if (contract_num === 1) {
      return "Nearest week contract";
    } else if (contract_num === 2) {
      return "2nd week contract";
    } else if (contract_num === 3) {
      return "3rd week contract";
    } else if (contract_num === 4) {
      return "last month/ last week contract";
    }
  };

  const deleteBasedOnItem = async (id) => {
    setLoading(true);
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    const body = {
      based_on_id: id,
    };

    // console.log("body : ", body);

    await fetch("https://app.stoxviews.com/api/delete-basedon", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        // console.log("based on script item deleted : ", value);
        setLoading(false);
        if (value.status) {
          toast.success(`${value.message}`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          getScriptSettingBasedOn();
        } else {
          toast.error(`deletion Failed`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const sellLiveSymbol = async (sellData) => {
    setLoading(true);
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    const body = {
      symbol_id: sellData.id,
      user_id: userprofile.id,
    };
    // console.log("sell data : ", body);
    await fetch("https://app.stoxviews.com/api/selllivesymbol", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        // console.log("sell live symbol data : ", value);
        setLoading(false);
        if (value.status) {
          toast.success(`${value.message}`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(`${value.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const buyLiveSymbol = async (buyData) => {
    setLoading(true);
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    const body = {
      symbol_id: buyData.id,
      user_id: userprofile.id,
    };
    // console.log("buy data : ", body);
    await fetch("https://app.stoxviews.com/api/buylivesymbol", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        // console.log("buy live symbol data : ", value);
        setLoading(false);
        if (value.status) {
          toast.success(`${value.message}`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(`${value.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        console.log(err);
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  return (
    <Layout>
      {isScriptOrBased ? (
        <div className="p-4 container">
          <div className="flex flex-row items-center justify-between mb-4">
            <div className="flex  items-center ">
              <h1 className="text-xl font-bold mr-[36px]">Script's List</h1>
            </div>

            <div className="mr-[16PX]">
              <button>
                <img
                  src={DownloadIcon}
                  alt="download"
                  style={{ width: 14, height: 16 }}
                />
              </button>
            </div>
          </div>

          <table className="min-w-full bg-white orderTable">
            <thead className="orderTable">
              <tr>
                <th className="px-4 py-2">
                  <img
                    src={selectIcon}
                    alt="select option"
                    style={{ width: 16, height: 16 }}
                  />
                </th>

                <th className="px-4 py-2 border">Instrument</th>
                <th className="px-4 py-2 border">Ltp</th>
                <th className="px-4 py-2 border">QTY</th>
                <th className="px-4 py-2 border">Order type</th>
                <th className="px-4 py-2 border">Product type</th>
                <th className="px-4 py-2 border">Datasource symbol</th>
                <th className="px-4 py-2 border">Actions</th>
              </tr>
            </thead>
            <tbody>
              {scripts?.map((position, index) => {
                const token_id = position?.token_id;
                // console.log("script list item : ",position);

                return (
                  <tr key={index}>
                    <td className="px-4 py-2 border orderTabletd">
                      <img
                        src={selectIcon}
                        alt="select option"
                        style={{ width: 16, height: 16 }}
                      />
                    </td>

                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {token_id?.trading_symbol}
                    </td>

                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={{
                        fontSize: 14,
                        color: position?.change <= 0 ? "#DF2123" : "green",
                      }}
                    >
                      {position?.ltp}
                    </td>

                    <td className="px-4 py-2 border orderTabletd">
                      {position?.qty}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position?.order_type}
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      {position?.product_type}
                    </td>

                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={
                        {
                          // color: parseInt(position.LTP) > 0 ? "#39A245" : "#DF2123",
                        }
                      }
                    >
                      {position?.datasource_symbol}
                    </td>
                    <td className="px-2 py-1 border orderTabletd ">
                      <div className="flex flex-row items-center">
                        <button
                          title="Buy!"
                          //onClick={() => onBuyClickOnModal(position)}
                          onClick={() => buyLiveSymbol(position)}
                          className="pl-[7px] pr-[7px] mr-[4px]"
                          style={{
                            backgroundColor: "#D4EDDA",
                            borderRadius: 4,
                            fontSize: 11,
                            color: "#155724",
                          }}
                        >
                          Buy
                        </button>
                        <button
                          title="Sell!"
                          //  onClick={() => onSellClickOnModal(position)}
                          onClick={() => sellLiveSymbol(position)}
                          className="pl-[7px] pr-[7px] mr-[8px]"
                          style={{
                            backgroundColor: "#F8D7DA",
                            borderRadius: 4,
                            fontSize: 11,
                            color: "#155724",
                          }}
                        >
                          Sell
                        </button>
                        <button title="edit!">
                          <img
                            src={editIcon}
                            onClick={() => {}}
                            alt="edit icon"
                            style={{ width: 18, height: 18, marginRight: 8 }}
                          />
                        </button>
                        <button
                          title="Delete!"
                          onClick={() => onDeleteScriptItem(position?.id)}
                        >
                          <img
                            src={deleteIcon}
                            alt="delete icon"
                            style={{ width: 18, height: 18 }}
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      ) : (
        <div className="p-4 container">
          <div className="flex flex-row items-center justify-between mb-4">
            <div className="flex  items-center ">
              <h1 className="text-xl font-bold mr-[36px]">Based On settings</h1>
            </div>

            <div className="mr-[16PX]">
              <button>
                <img
                  src={DownloadIcon}
                  alt="download"
                  style={{ width: 14, height: 16 }}
                />
              </button>
            </div>
          </div>

          <table className="min-w-full bg-white orderTable">
            <thead className="orderTable">
              <tr>
                <th className="px-4 py-2">
                  <img
                    src={selectIcon}
                    alt="select option"
                    style={{ width: 16, height: 16 }}
                  />
                </th>

                <th className="px-4 py-2 border">Index Derivatives</th>
                <th className="px-4 py-2 border">Trigger Symbol</th>
                <th className="px-4 py-2 border">When Buy Setting</th>
                <th className="px-4 py-2 border">When Sell Settings</th>
                <th className="px-4 py-2 border">Delete</th>
              </tr>
            </thead>
            <tbody>
              {scriptSettingBasedOn?.map((position, index) => {
                let sym_id__token_id = position?.sym_id__token_id;
                let when_sell = position?.when_sell;
                let when_buy = position?.when_buy;

                //console.log("script list item : ",position);

                return (
                  <tr key={index}>
                    <td className="px-4 py-2 border orderTabletd">
                      <img
                        src={selectIcon}
                        alt="select option"
                        style={{ width: 16, height: 16 }}
                      />
                    </td>

                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={{
                        fontSize: 14,
                      }}
                    >
                      {position?.idx}
                    </td>

                    <td
                      className="px-4 py-2 border orderTabletd"
                      style={{
                        fontSize: 14,
                        color: position?.change <= 0 ? "#DF2123" : "green",
                      }}
                    >
                      {`${sym_id__token_id.trading_symbol} / ${position?.sym_id__datasource_symbol}`}
                    </td>

                    <td className="px-4 py-2 border orderTabletd">
                      <div className="flex flex-wrap">
                        <span
                          style={{
                            backgroundColor: "#f5f5f5",
                            fontSize: 11,
                            borderRadius: 4,
                          }}
                          className="ml-2 px-1 py-[.5px] mb-1"
                        >
                          Option type : {when_buy.derivative}
                        </span>
                        <span
                          className="ml-2 px-1 py-[.5px] mb-1"
                          style={{
                            backgroundColor: "#f5f5f5",
                            fontSize: 11,
                            borderRadius: 4,
                          }}
                        >
                          Action : {when_buy.action}
                        </span>
                        <span
                          className="ml-2 px-1 py-[.5px] mb-1"
                          style={{
                            backgroundColor: "#f5f5f5",
                            fontSize: 11,
                            borderRadius: 4,
                          }}
                        >
                          Atm : {when_buy.atm}
                        </span>
                        <span
                          className="ml-2 px-1 py-[.5px] mb-1"
                          style={{
                            backgroundColor: "#f5f5f5",
                            fontSize: 11,
                            borderRadius: 4,
                          }}
                        >
                          Qty : {when_buy.qty}
                        </span>
                        <span
                          className="ml-2 px-1 py-[.5px] mb-1 "
                          style={{
                            backgroundColor: "#f5f5f5",
                            fontSize: 11,
                            borderRadius: 4,
                          }}
                        >
                          Contract type :{" "}
                          {selectScriptContract(position?.contract_type)}
                        </span>
                      </div>
                    </td>
                    <td className="px-4 py-2 border orderTabletd">
                      <div className="flex flex-wrap">
                        <span
                          style={{
                            backgroundColor: "#f5f5f5",
                            fontSize: 11,
                            borderRadius: 4,
                          }}
                          className="ml-2 px-1 py-[.5px] mb-1"
                        >
                          Option type : {when_sell.derivative}
                        </span>
                        <span
                          className="ml-2 px-1 py-[.5px] mb-1"
                          style={{
                            backgroundColor: "#f5f5f5",
                            fontSize: 11,
                            borderRadius: 4,
                          }}
                        >
                          Action : {when_sell.action}
                        </span>
                        <span
                          className="ml-2 px-1 py-[.5px] mb-1"
                          style={{
                            backgroundColor: "#f5f5f5",
                            fontSize: 11,
                            borderRadius: 4,
                          }}
                        >
                          Atm : {when_sell.atm}
                        </span>
                        <span
                          className="ml-2 px-1 py-[.5px] mb-1"
                          style={{
                            backgroundColor: "#f5f5f5",
                            fontSize: 11,
                            borderRadius: 4,
                          }}
                        >
                          Qty : {when_sell.qty}
                        </span>
                        <span
                          className="ml-2 px-1 py-[.5px] mb-1 "
                          style={{
                            backgroundColor: "#f5f5f5",
                            fontSize: 11,
                            borderRadius: 4,
                          }}
                        >
                          Contract type :{" "}
                          {selectScriptContract(position?.contract_type)}
                        </span>
                      </div>
                    </td>

                    <td className="px-2 py-1 border orderTabletd ">
                      <div className="flex flex-row items-center justify-center">
                        <button
                          title="Delete!"
                          onClick={() => deleteBasedOnItem(position?.id)}
                        >
                          <img
                            src={deleteIcon}
                            alt="delete icon"
                            style={{ width: 18, height: 18 }}
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      <BuyAndSellModal isOpen={bopen} setIsOpen={setBopen} />
    </Layout>
  );
}

export default ScriptSetting;
