import React, {
  useState,
  useEffect,
  Fragment,
  useContext,
  useRef,
} from "react";

import searchIcon from "../../assets/icons/search.png";
import closeIcon from "../../assets/icons/close.png";
import menuIcon from "../../assets/icons/menu.png";
import editIcon from "../../assets/icons/edit.png";
import pencilIcon from "../../assets/icons/pencil.png";
import downIcon from "../../assets/icons/redDown.png";
import down2Icon from "../../assets/icons/down.png";
import downgray from "../../assets/icons/downgray.png";
import vectorIcon from "../../assets/icons/vector.png";
import BuyIcon from "../../assets/icons/Buy.png";
import SellIcon from "../../assets/icons/Sell.png";
import DeleteIcon from "../../assets/icons/Delete.png";
import ChartIcon from "../../assets/icons/Chart.png";
import BoxIcon from "../../assets/icons/Box.png";
import AddIcon from "../../assets/icons/Add.png";
import featuresIcon from "../../assets/icons/features.png";
import reloadIcon from "../../assets/icons/reload.png";
import AddedIcon from "../../assets/icons/Added.png";
import upIcon from "../../assets/icons/up.png";
import rupeeIcon from "../../assets/icons/rupee.png";
import { MdOutlineClose } from "react-icons/md";
import { TiTick } from "react-icons/ti";
import { FcOk } from "react-icons/fc";
import "./watchstyle.css";
import "../../styles/optionchain.css";
import "../../styles/scriptsetting.css";
import Modal from "react-modal";
import { Tab } from "@headlessui/react";
import MyContext from "../../context/MyContext";
import { useSelector } from "react-redux";
import { toast } from "react-toastify";
import Loader, { SmallLoader } from "../loader/Loader";
import BuyAndSellModal from "../modals/BuyAndSellModal";
import { useNavigate } from "react-router-dom";

const customStyles = {
  content: {
    top: "50%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    // marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    width: "522px",
    // height: "343px",
    padding: "0px",
    borderRadius: "4px",
  },
  overlay: {
    backgroundColor: "white",
  },
};

function WatchList() {
  const {
    watchListShown,
    watch_list,
    set_watch_list,
    isChildAccount,
    getDashboardCall,
    userprofile,
    loading,
    setLoading,
    pageName,
    setChartToken,
    isToggled,
    setIsToggled,
    bopen,
    setBopen,
    buyAndSellExchangeToken,
    setBuyAndSellExchangeToken,
    buyAndSellExchange,
    setBuyAndSellExchange,
    buyAndSellTradingSymbol,
    setBuyAndSellTradingSymbol,
    buyAndSellInstrumentType,
    setBuyAndSellInstrumentType,
    setBuyAndSellAction,
    buyAndSellAction,
    optionChainExpiry,
    optionchainwatchlist,
    optionchainwatchlist2,
    optionChainDate,
    setOptionChainDate,
    setOptionChainWatchList,
    setQty,
    qty,
    setBuyAndSellModalLivePrice,
    ltpValue,
    setPageName,
    isDemoPage,
    onScriptSetting,
    isScriptOrBased,
    setIsScriptOrBased,
    scripts,
    getScriptSettingBasedOn,
    myWatchList,
    setMyWatchList,
    getWatchLists,
    watchingList,
    setWatchingList,
    lotSize,
    setLotSize,
    ws,
    headLivePrice,
    setIsPendingOrder,
    setOrderType,
    setProductType,
    orderType,
    productType,
    callGetOptionChain,
  } = useContext(MyContext);
  const navigate = useNavigate();
  const [isSearchActive, setIsSearchActive] = useState(true);
  const [searchItem, setSearchItem] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [exchangeName, setExchangeName] = useState("NSE");
  const [scriptSettingExchangeName, setScriptSettingExchangeName] =
    useState("NSE");
  const [isMoreDropdown, setIsMoreDropdown] = useState(false);
  const [isBuy, setIsBuy] = useState("Buy");
  const [activeTab, setActiveTab] = useState("regular");
  const [isDisabled, setIsDisabled] = useState(false);
  const [validity, setValidity] = useState("DAY");
  const [showEdit, setShowEdit] = useState(true);
  const [renameLoader, setRenameLoader] = useState(false);

  //script setting form
  const [selectedScrOrderType, setSelectedScrOrderType] = useState("DELIVERY");
  const [selectedScrProductType, setSelectedScrProductType] =
    useState("MARKET");
  const [selectedScrStopLossType, setSelectedScrStopLossType] =
    useState("FIXED");
  const [scrQty, setScrQty] = useState("");
  const [scrStoploss, setScrStoploss] = useState("");
  const [renamedSegmentName, setRenamedSegmentName] = useState("");

  const [optionChainList, setOptionChaiList] = useState([{}, {}, {}]);
  //modal data
  const [token, setToken] = useState("");
  const [trading_symbol, setTrading_Symbol] = useState("");
  const [exchange, setExchange] = useState("");
  const [instrument_type, setInstrument_type] = useState("");
  const [order_type, setOrder_type] = useState("");
  const [product_type, setProduct_type] = useState("");
  const [oicalltoggled, setOiCallToggled] = useState(false);
  const [oiputtoggled, setOiPutToggled] = useState(false);
  const [sstrading_symbol, setSSTrading_Symbol] = useState("");
  const [ssField, setSSField] = useState({});
  const [
    selectedScriptSettingRadioOption,
    setSelectedScriptSettingRadioOption,
  ] = useState("");
  const [segmentName, setSegmentName] = useState("");
  const [optionChainType, setOptionChainType] = useState("NIFTY");
  const rowRefs = useRef([]);
  const onChangeScriptSetting = (e) => {
    setSelectedScriptSettingRadioOption(e.target.value);
  };
  const [instrumentLoader, setInstrumentLoader] = useState(false);

  const [symbol, setSymbol] = useState({});
  const [segment, setSegment] = useState("bucket_a");

  const [value1, setValue1] = useState("");
  const [value2, setValue2] = useState("");
  const [value3, setValue3] = useState("");

  const [tvalue, setTValue] = useState("");
  const [unit1, setUnit1] = useState("%");
  const [unit2, setUnit2] = useState("%");
  const [unit3, setUnit3] = useState("%");

  // based on form
  // "scrip":"",
  const [scrip, setScrip] = useState("");
  // "idx":"",
  const [selectIdx, setSelectIdx] = useState("NIFTY");
  // "contract_type":"",
  const [contract_type, setContract_type] = useState(1);
  // "whenbuycepe":"",
  const [whenbuycepe, setwhenbuycepe] = useState("CE");
  // "whenbuyatm":"",
  const [whenbuyatm, setwhenbuyatm] = useState("100");
  // "whenbuyact":"",
  const [whenbuyact, setwhenbuyact] = useState("BUY");
  // "whenbuyqty":"",
  const [whenbuyqty, setwhenbuyqty] = useState("");
  // "whenbuyrange":"",
  const [whenbuyrange, setwhenbuyrange] = useState("");

  // "whensellcepe":"",
  const [whensellcepe, setwhensellcepe] = useState("CE");
  // "whensellatm":"",
  const [whensellatm, setwhensellatm] = useState("100");
  // "whensellact":"",
  const [whensellact, setwhensellact] = useState("BUY");
  // "whensellqty":"",
  const [whensellqty, setwhensellqty] = useState("");
  // "whensellrange":""
  const [whensellrange, setwhensellrange] = useState("");

  const inputRef = useRef(null);
  const dropdownRef = useRef(null);
  const [isClickedOutside, setIsClickedOutside] = useState(false);

  const handleClickOutside = (event) => {
    if (
      inputRef.current &&
      !inputRef.current.contains(event.target) &&
      dropdownRef.current &&
      !dropdownRef.current.contains(event.target)
    ) {
      setIsSearchActive(true);
    }
  };

  useEffect(() => {
    // Attach event listener for mouse click events
    document.addEventListener("mousedown", handleClickOutside);

    // Cleanup listener on component unmount
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const handleUnitChange1 = (selectedUnit) => {
    setUnit1(selectedUnit);
  };
  const handleUnitChange2 = (selectedUnit) => {
    setUnit2(selectedUnit);
  };
  const handleUnitChange3 = (selectedUnit) => {
    setUnit3(selectedUnit);
  };

  const handleInputChange1 = (e) => {
    const newValue = e.target.value;

    if (newValue.length !== 0) {
      setValue1(`${newValue}`);
    } else {
      setValue1("");
    }
  };

  const handleInputChange2 = (e) => {
    const newValue = e.target.value;

    if (newValue.length !== 0) {
      setValue2(`${newValue}`);
    } else {
      setValue2("");
    }
  };
  const handleInputChange3 = (e) => {
    const newValue = e.target.value;

    if (newValue.length !== 0) {
      setValue3(`${newValue}`);
    } else {
      setValue3("");
    }
  };

  const tabsData = [
    { label: "Regular", value: "regular", desc: "Content for Regular" },
    { label: "Cover", value: "cover", desc: "Content for Cover" },
    { label: "AMO", value: "amo", desc: "Content for AMO" },
  ];

  const [watchlistOption, setWatchListOption] = useState([
    { name: "ALL", isSelected: true },
    { name: "STOCK", isSelected: false },
    // { name: "NFO", isSelected: false },
    { name: "OPT", isSelected: false },
    { name: "FUT", isSelected: false },
    { name: "MCX", isSelected: false },
    { name: "CDS", isSelected: false },
  ]);

  const [watchLists, setWatchLists] = useState([]);

  const [addedWatchLists, setAddedWatchLists] = useState([
    { isProgress: false, isHover: false },
    { isProgress: true, isHover: false },
  ]);

  const [searchingWatchLists, setSearchingWatchLists] = useState([]);
  const [currentAddSegment, setCurrentAddSegment] = useState("a");
  const [pagination, setPagination] = useState([
    { name: "A", value: "bucket_a", isSelected: true },
    { name: "B", value: "bucket_b", isSelected: false },
    { name: "C", value: "bucket_c", isSelected: false },
    { name: "D", value: "bucket_d", isSelected: false },
    { name: "E", value: "bucket_e", isSelected: false },
  ]);

  const handleMouseOver = (item, index) => {
    const newWatchListOption = watchlistOption.map((it, idx) => {
      if (index === idx) {
        it.isSelected = true;
      } else {
        it.isSelected = false;
      }
      return it;
    });
    setWatchListOption(newWatchListOption);
  };

  const setModalData = (item, action, lastPrice) => {
    // console.log("model data : ", item);
    setIsPendingOrder(false);
    setChartToken(item.exchange_token);
    setBuyAndSellExchange(item.exchange);
    setBuyAndSellTradingSymbol(item.trading_symbol);
    setBuyAndSellInstrumentType(item.instrument_type);
    setOrderType("Limit");
    setProductType("Intraday");

    // setBuyAndSellAction(action);
    setBuyAndSellModalLivePrice(lastPrice);
    if (item.lot_size === null) {
      setLotSize(1);
    } else {
      setLotSize(item.lot_size);
    }
    setQty("");
    if (action === "BUY") {
      setBuyAndSellAction("buy");
      setIsBuy("buy");
      setIsToggled(false);
    } else {
      setBuyAndSellAction("sell");
      setIsBuy("sell");
      setIsToggled(true);
    }
    setBopen(true);
  };

  const handleChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleMouseOut = (item, index) => {};

  const mouseOverOnWatchLists = (item, index) => {
    // const newWatchLists = myWatchList.map((it, idx) => {
    //   if (index === idx) {
    //     it.isHover = true;
    //   } else {
    //     it.isHover = false;
    //   }

    //   return it;
    // });
    // setMyWatchList(newWatchLists);

    const newWatchLists = watchingList.map((it, idx) => {
      if (index === idx) {
        it[1].isHover = true;
      } else {
        it[1].isHover = false;
      }

      return it;
    });
    setWatchingList(newWatchLists);
  };

  const mouseOutOnWatchLists = (item, index) => {
    const newWatchLists = watchingList.map((it, idx) => {
      it[1].isHover = false;
      return it;
    });
    setWatchingList(newWatchLists);
  };

  const onSearch = (e) => {
    setIsSearchActive(false);
    setSearchItem(e.target.value);
    if (e.target.value.length === 0) {
      setIsSearchActive(true);
    }

    const filteredSymbols = optionchainwatchlist2.filter((symbol) => {
      const prefix = symbol[0].trading_symbol.split("P")[0];
      return prefix.toLowerCase().includes(e.target.value);
    });

    //console.log("filtered symbol : ", filteredSymbols);
    setOptionChainWatchList(filteredSymbols);
  };

  const mouseOverOnSearchingLists = (item, index) => {
    const newWatchLists = searchingWatchLists.map((it, idx) => {
      if (index === idx) {
        it.isHover = true;
      } else {
        it.isHover = false;
      }
      return it;
    });
    setSearchingWatchLists(newWatchLists);
  };

  const mouseOutOnSearchingLists = (item, index) => {
    const newWatchLists = searchingWatchLists.map((it, idx) => {
      it.isHover = false;
      return it;
    });
    setSearchingWatchLists(newWatchLists);
  };

  const onClickWatchListOption = (item, index) => {
    switch (item.name) {
      case "ALL":
        setExchangeName("NSE");
        break;

      case "STOCK":
        setExchangeName("NSE");
        break;

      case "FUT":
        setExchangeName("FUT");
        break;

      // case "NFO":
      //   // console.log("Exchange name : NFO");
      //   setExchangeName("NFO");

      case "OPT":
        setExchangeName("OPT");
        break;

      case "MCX":
        setExchangeName("MCX");
        break;

      case "CDS":
        setExchangeName("CDS");
        break;
      // default:
      //   setExchangeName("NSE");
      //   break;
    }

    const newWatchListOption = watchlistOption.map((it, idx) => {
      if (index === idx) {
        it.isSelected = true;
      } else {
        it.isSelected = false;
      }
      return it;
    });
    setWatchListOption(newWatchListOption);
  };

  const onScriptSettingListOption = (item, index) => {
    switch (item.name) {
      case "ALL":
        // console.log("SCRIPT SETTING EXCHANGE NAME : NSE");
        setScriptSettingExchangeName("NSE");
        break;
      case "FUT":
        // console.log("SCRIPT SETTING EXCHANGE NAME : FUT");
        setScriptSettingExchangeName("FUT");
        break;
      case "OPT":
        // console.log("SCRIPT SETTING EXCHANGE NAME : NFO");
        setScriptSettingExchangeName("OPT");
        break;
      case "MCX":
        // console.log("SCRIPT SETTING EXCHANGE NAME : MCX");
        setScriptSettingExchangeName("MCX");
        break;
    }
  };

  const instrumentTypeBackgroundColor = (instrument_type) => {
    let back;
    switch (instrument_type) {
      case "CE":
        back = "rgba(13,153,255,.3)";
        break;
      case "PE":
        back = "rgba(13,153,255,.3)";
        break;
      case "EQ":
        back = "#F8D7DA";
        break;
      case "FUT":
        back = "#D4EDDA";
        break;
      default:
        back = "#D4EDDA";
    }
    return back;
  };

  const instrumentType = (field) => {
    let type;
    if (field.instrument_type === "CE") {
      type = "OPT";
    } else if (field.instrument_type === "PE") {
      type = "OPT";
    } else {
      if (field.exchange === "MCX") {
        if (field.segment === "FUTCOM") {
          type = "FUT";
        }
      } else {
        type = field.instrument_type;
      }
    }
    return type;
  };

  const handleToggle = () => {
    if (!isToggled) {
      setIsBuy("Sell");
    } else {
      setIsBuy("Buy");
    }
    setIsToggled(!isToggled);
  };

  const handleOICall = () => {
    setOiCallToggled(!oicalltoggled);
  };

  const handleOIPut = () => {
    setOiPutToggled(!oiputtoggled);
  };

  const getScript = async () => {
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));

    await fetch(
      `https://app.stoxviews.com/api/getScrip/${exchangeName}/${searchItem}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionID}`,
        },
      }
    )
      .then((res) => res.json())
      .then((value) => {
        // console.log("value : ", JSON.parse(value)[0]);

        setSearchingWatchLists(
          JSON.parse(value).map((item, index) => {
            item.isHover = false;
            item.isAdded = false;
            return item;
          })
        );
      })
      .catch((err) => {
        console.log("Get script error:", err);
      });
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  const addWatchListCall = async (item, index, fields) => {
    let dyanamicItem = item;
    const propertiesToDelete = [
      "isAdded",
      "isHover",
      // "last_traded_price",
      // "average_traded_price",
    ];
    propertiesToDelete.forEach((prop) => delete dyanamicItem[prop]);

    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    const body = {
      segment: currentAddSegment,
      instrument: dyanamicItem,
    };

    // console.log("adding watchlist : ", body);

    await fetch("https://app.stoxviews.com/api/addWatchList", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        // console.log("add WatchList value : ", value.data);
        if (value.status) {
          const newSearchingWatchLists = searchingWatchLists.map((it, idx) => {
            if (idx === index) {
              it.isAdded = true;
            }
            return it;
          });
          setSearchingWatchLists(newSearchingWatchLists);
          let addingRequest = {
            action: "sendMessage",
            message: {
              event: "add",
              data: "t",
            },
          };
          ws.current?.send(JSON.stringify(addingRequest));

          let data = value.data;
          // console.log("daata : ", data);

          let fields = data.fields;

          let tds = fields.trading_symbol;
          let keyName = tds.toUpperCase();
          let newInstrument = data;
          const updatedWatchList = { ...myWatchList };

          updatedWatchList[segment].instruments[keyName] = {
            ...newInstrument,
            isProgress: false,
            isHover: false,
            isAdd: false,
            last_price: data?.last_price,
            // average_traded_price: 0.0,
            change_price: data?.price_change,
          };
          setMyWatchList(updatedWatchList);

          toast.success(`${value.message}`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(`${value.message}`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }

        //getDashboardCall();
        //getWatchLists();
        // myWatchList[segment]?.instruments
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const onFilterPagination = (item, index) => {
    setSegment(item.value);
    setCurrentAddSegment(item?.name?.toLowerCase());
    setRenamedSegmentName(myWatchList[item.value]?.name);
    const newPagination = pagination.map((it, idx) => {
      it.isSelected = index === idx;
      return it;
    });
    setPagination(newPagination);
  };

  const onClickBuyAndSell = async () => {
    setLoading(true);
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    const body = {
      token: token,
      trading_symbol: trading_symbol,
      exchange: exchange,
      order_type: orderType,
      product_type: productType,
      qty: qty,
      validity: validity,
      action: isBuy,
    };

    await fetch("https://app.stoxviews.com/api/placeorder", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        if (value.status) {
          setLoading(false);
          toast.success(`${isBuy} successfully`, {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setBopen(false);
          setQty("");
        } else {
          setLoading(false);
          toast.error(`${isBuy} Failed`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setBopen(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        console.log(err);
        toast.error(`${isBuy} Failed`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      });
  };

  const [instrumentName, setInstrumentName] = useState("");
  const [scriptSettingRadioOption, setScriptSettingRadioOption] = useState([
    { name: "NSE", isSelected: false },
    { name: "OPT", isSelected: false },
    { name: "FUT", isSelected: false },
    { name: "MCX", isSelected: false },
    // { name: "CDS", isSelected: false },
  ]);
  const [isTooltipVisible, setIsTooltipVisible] = useState(false);
  const [tooltipData, setTooltipData] = useState([]);
  const [sname, setSname] = useState("");

  const handleFocus = () => {
    setIsTooltipVisible(true);
  };

  useEffect(() => {
    if (searchItem.length !== 0) {
      getScript();
    }
  }, [searchItem, exchangeName]);

  useEffect(() => {
    if (JSON.stringify(myWatchList) !== "{}") {
      let my = Object.entries(myWatchList[segment]?.instruments);
      setWatchingList(my);
      // setRenamedSegmentName(my[0]?.name)
      // console.log("my segment : ", my);
    }
  }, [myWatchList, segment]);

  useEffect(() => {
    if (instrumentName.length !== 0) {
      callScriptSettingApi();
    }
  }, [instrumentName]);

  const callScriptSettingApi = async () => {
    setInstrumentLoader(true);
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    // console.log("EXCHANGE NAME :", scriptSettingExchangeName);
    // console.log("Instument Name :", instrumentName);
    // console.log("sessionID :", sessionID);
    await fetch(
      `https://app.stoxviews.com/api/getScrip/${scriptSettingExchangeName}/${instrumentName}`,
      {
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${sessionID}`,
        },
      }
    )
      .then((res) => res.json())
      .then((value) => {
        // console.log("script setting value : ", value);
        setTooltipData(JSON.parse(value));
        setInstrumentLoader(false);
        // setSearchingWatchLists(
        //   JSON.parse(value).map((item, index) => {
        //     item.isHover = false;
        //     item.isAdded = false;
        //     return item;
        //   })
        // );
      })
      .catch((err) => {
        console.log("Get script error:", err);
      });
  };

  const goToChartPage = (
    exchange,
    trading_symbol,
    instrument_token,
    last_traded_price,
    item
  ) => {
    // console.log("item chart : ", instrument_token);

    setBuyAndSellExchange(exchange);
    setBuyAndSellTradingSymbol(trading_symbol);
    setChartToken(instrument_token);
    setBuyAndSellModalLivePrice(last_traded_price);
    setLotSize(item.lot_size);
    // setBuyAndSellInstrumentType(item.instrument_type);
    if (pageName !== "optionchain") {
      navigate("/charts");
    }
  };

  const optionchainwatchlistfilter = () => {
    const newoptionchainwatchlist = optionchainwatchlist.filter(
      (item, index) => {
        return item[0]?.expiry === optionChainDate;
      }
    );
    return newoptionchainwatchlist;
  };

  // useEffect(() => {
  //   //setBopen(true)
  // }, [optionChainDate]);

  const onMouseCallOverOptionChain = (index) => {
    setOptionChaiList(
      optionchainwatchlistfilter().map((it, idx) => {
        if (index === idx) {
          if (it.isCallHover) {
            it.isCallHover = false;
          } else {
            it.isCallHover = true;
          }
        } else {
          it.isCallHover = false;
        }
      })
    );
  };

  const onMouseCallOverOutOptionChain = (index) => {
    setOptionChaiList(
      optionchainwatchlistfilter().map((it, idx) => {
        it.isCallHover = false;
      })
    );
  };

  const onMousePutOverOptionChain = (index) => {
    setOptionChaiList(
      optionchainwatchlistfilter().map((it, idx) => {
        if (index === idx) {
          if (it.isPutHover) {
            it.isPutHover = false;
          } else {
            it.isPutHover = true;
          }
        } else {
          it.isPutHover = false;
        }
      })
    );
  };

  const onMousePutOverOutOptionChain = (index) => {
    setOptionChaiList(
      optionchainwatchlistfilter().map((it, idx) => {
        it.isPutHover = false;
      })
    );
  };

  const onOpenModal = (
    action,
    trading_symbol,
    Exchange,
    token,
    lastPrice,
    item
  ) => {
    // console.log("optionchain : ", item);
    setIsPendingOrder(false);
    setBopen(true);
    setBuyAndSellTradingSymbol(trading_symbol);
    setBuyAndSellExchangeToken(token);
    setChartToken(token);
    setBuyAndSellExchange(Exchange);
    setBuyAndSellModalLivePrice(lastPrice);
    setQty("");
    setLotSize(item.lot_size);
    setOrderType("Limit");
    setProductType("Intraday");
    if (action === "BUY") {
      setBuyAndSellAction("buy");
      setIsToggled(false);
    } else {
      setBuyAndSellAction("sell");
      setIsToggled(true);
    }
  };

  const [ltpIndex, setLtpIndex] = useState(-1);
  const [instrumentOpen, setInstrumentOpen] = useState(false);

  useEffect(() => {
    // console.log("headLivePrice.last_price : ",headLivePrice.last_price);
    
    //optionchainwatchlist
    const foundIndex = optionchainwatchlistfilter().findIndex((obj) => {
      //const numericPart = parseInt(obj[0].trading_symbol.split("P")[1]);
      let opstring = obj[0].trading_symbol;
      let lastPindex = opstring.lastIndexOf("P");
      let value_after_p = opstring.substring(lastPindex + 1);
      let numericPart = parseInt(value_after_p);
      return numericPart > ltpValue;
    });
    // console.log("numeric parting : ", foundIndex - 1);
    setLtpIndex(foundIndex - 1);

    if (foundIndex !== -1 && rowRefs.current[foundIndex - 1]) {
      rowRefs.current[foundIndex - 1].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    }
  }, [optionchainwatchlist.length, pageName, optionChainDate, optionChainType]);

  const onscriptcall = async (e) => {
    e.preventDefault();
    setLoading(true);
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    const body = {
      instid: ssField?.pk,
      qty: scrQty,
      stoploss: value1 + unit1,
      stype: selectedScrStopLossType,
      dssym: sname,
      ottype: selectedScrProductType,
      pttype: selectedScrOrderType,
    };

    // console.log("script setting body : ", body);

    await fetch("https://app.stoxviews.com/api/scripsetting", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        // console.log("SCRIPT SETTING : ", value);

        if (value.status) {
          setLoading(false);
          onScriptSetting();
          toast.success("script setting created", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(`Something went wrong`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("Error : ", err);
      });
  };

  const changeWatchListName = async (e) => {
    e.preventDefault();
    setRenameLoader(true);
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    const body = {
      name: renamedSegmentName,
      segment: currentAddSegment,
    };

    // console.log("watchlist rename body : ", body);

    await fetch("https://app.stoxviews.com/api/editwatchlistname", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        // console.log("SCRIPT SETTING : ", value);
        setRenameLoader(false);
        if (value.status) {
          toast.success("Watchlist name renamed", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          // getWatchLists();
          setMyWatchList((prevState) => ({
            ...prevState,
            [segment]: {
              ...prevState[segment],
              name: renamedSegmentName,
            },
          }));
          setShowEdit(true);
        } else {
          toast.error(`Something went wrong`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("Error : ", err);
      });
  };

  const onDeleteWatchListItem = async (instrumentName) => {
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    const body = {
      instrument: instrumentName,
      segment: currentAddSegment,
    };

    // console.log("watchlist delete body : ", body);

    await fetch("https://app.stoxviews.com/api/removewatchlist", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        if (value.status) {
          toast.success("Watchlist item deleted", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          const updatedWatchList = { ...myWatchList };
          delete updatedWatchList[segment].instruments[instrumentName];
          setMyWatchList(updatedWatchList);
        } else {
          toast.error(`Something went wrong`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      })
      .catch((err) => {
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("Error : ", err);
      });
  };

  const basedonscript = async (e) => {
    e.preventDefault();
    setLoading(true);
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    const body = {
      scrip: scrip,
      idx: selectIdx,
      contract_type: contract_type,
      whenbuycepe: whenbuycepe,
      whenbuyatm: whenbuyatm,
      whenbuyact: whenbuyact,
      whenbuyqty: whenbuyqty,
      whenbuyrange: whenbuyrange,
      whensellcepe: whensellcepe,
      whensellatm: whensellatm,
      whensellact: whensellact,
      whensellqty: whensellqty,
      whensellrange: whensellrange,
    };

    // console.log("based on : ", body);

    await fetch("https://app.stoxviews.com/api/basedon", {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${sessionID}`,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((value) => {
        // console.log("Based on submitted : ", value);

        if (value.status) {
          setLoading(false);
          getScriptSettingBasedOn();
          toast.success("based on submitted", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        } else {
          toast.error(`Something went wrong`, {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setLoading(false);
        }
      })
      .catch((err) => {
        setLoading(false);
        toast.error(`Something went wrong`, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        console.log("Error : ", err);
      });
  };

  const handleChangeOption = (event) => {
    const selectedOption = event.target.value;
    setOptionChainType(selectedOption);
    const sessionID = JSON.parse(localStorage.getItem("sessionID"));
    if (selectedOption === "NIFTY") {
      callGetOptionChain(sessionID, "NIFTY");
    } else if (selectedOption === "BANK NIFTY") {
      callGetOptionChain(sessionID, "BANK NIFTY");
    }
  };

  const renderLeftSideContent = () => {
    switch (pageName) {
      case "optionchain":
        return (
          <div
            style={{
              marginTop: 16,
              marginRight: 20,

              // maxHeight: "100%",
              width: "100%",
              position: "relative",

              // overflow: "auto",
            }}
          >
            {/* <Loader/> */}

            {loading && <Loader />}

            <div className="optionchain-container relative">
              <div>
                {/* <span
                  style={{ fontSize: 12, color: "#817E7E", marginBottom: 5 }}
                >
                  Option Chain
                </span> */}

                <div className="flex flex-col mb-[8px] ">
                  <label
                    htmlFor="options"
                    className="mb-[5px]"
                    style={{ color: "#817e7e", fontSize: 14 }}
                  >
                    Option Chain
                  </label>
                  <select
                    id="options"
                    value={optionChainType}
                    onChange={handleChangeOption}
                    style={{
                      padding: "10px 5px",
                      borderRadius: 4,
                      borderWidth: 0.5,
                      borderColor: "#817e7e",
                      color: "#817e7e",
                    }}
                  >
                    <option value="NIFTY">NIFTY</option>
                    <option value="BANK NIFTY">BANK NIFTY</option>
                  </select>
                </div>

                {/* <div
                  style={{
                    marginBottom: searchItem.length != 0 ? 0 : 8,
                    // paddingVertical: 10,
                    borderRadius: 6,
                    borderWidth: 1,
                    borderColor: "#E6E6E6",
                    marginTop: 5,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      // paddingVertical: 10,
                      alignItems: "center",
                      marginLeft: 14,
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <img
                      src={searchIcon}
                      alt="search icon"
                      style={{ width: 12, height: 12, marginRight: 8 }}
                    />
                    <input
                      type="text"
                      value={searchItem}
                      onChange={(e) => onSearch(e)}
                      placeholder="scripts *"
                      style={{ fontSize: 12 }}
                      className="bg-gray-100 outline-none w-full text-gray-500"
                    />
                    {!isSearchActive && (
                      <img
                        src={closeIcon}
                        onClick={() => {
                          setIsSearchActive(true);
                          setSearchItem("");
                        }}
                        alt="close icon"
                        style={{ width: 12, height: 12, marginRight: 12 }}
                      />
                    )}
                  </div>
                </div> */}
              </div>

              <div className="flex flex-col mb-[8px] ">
                <label
                  htmlFor="options"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  Expiry
                </label>
                <select
                  id="options"
                  value={optionChainDate}
                  onChange={(event) => {
                    setOptionChainDate(event.target.value);
                  }}
                  style={{
                    padding: "10px 5px",
                    borderRadius: 4,
                    borderWidth: 0.5,
                    borderColor: "#817e7e",
                    color: "#817e7e",
                  }}
                >
                  {optionChainExpiry.map((item, index) => {
                    return (
                      <option
                        onClick={() => {
                          setSelectedOption(item[1]);
                        }}
                        value={item[1]}
                      >
                        {item[1]}
                      </option>
                    );
                  })}
                </select>
              </div>

              {/* <div className="absolute top-[30px] right-[30px]">dropdown</div> */}
            </div>

            <div style={{ overflowY: "auto", height: "82vh" }}>
              <table
                className="min-w-full bg-white orderTable"
                style={{ borderRadius: 4 }}
              >
                <thead
                  className="orderTable"
                  style={{ position: "sticky", top: 0, zIndex: 1 }}
                >
                  <tr>
                    <th className="px-4 py-2 border">
                      <div className="flex flex-row items-center ">
                        <div className="mr-[7px]">
                          <span
                            style={{
                              fontSize: 15,
                              fontWeight: "400",
                              color: "#817E7E",
                            }}
                          >
                            OI
                          </span>
                        </div>

                        <div className="flex items-center justify-center ml-[8px] mr-[8px]  bg-gray-100">
                          <button
                            className={`w-10 h-5 flex items-center rounded-full p-1 duration-300 ease-in-out`}
                            style={{
                              backgroundColor: oicalltoggled
                                ? "green"
                                : "green  ",
                            }}
                            onClick={() => handleOICall()}
                          >
                            <div
                              style={{ backgroundColor: "white" }}
                              className={` w-4 h-4  rounded-full shadow-md transform duration-300 ease-in-out ${
                                oicalltoggled
                                  ? "translate-x-4"
                                  : "translate-x-0"
                              }`}
                            />
                          </button>
                        </div>
                        <div className="mr-[21px]">
                          <span
                            style={{
                              fontSize: 15,
                              fontWeight: "400",
                              color: "#817E7E",
                            }}
                          >
                            Call
                          </span>
                        </div>
                      </div>
                    </th>
                    <th className="px-4 py-2 border">Strike price</th>
                    <th className="px-4 py-2 border">
                      <div className="flex flex-row items-center ">
                        <div className="mr-[7px]">
                          <span
                            style={{
                              fontSize: 15,
                              fontWeight: "400",
                              color: "#817E7E",
                            }}
                          >
                            OI
                          </span>
                        </div>

                        <div className="flex items-center justify-center ml-[8px] mr-[8px]  bg-gray-100">
                          <button
                            className={`w-10 h-5 flex items-center rounded-full p-1 duration-300 ease-in-out`}
                            style={{
                              backgroundColor: oiputtoggled
                                ? "green"
                                : "green  ",
                            }}
                            onClick={() => handleOIPut()}
                          >
                            <div
                              style={{ backgroundColor: "white" }}
                              className={` w-4 h-4  rounded-full shadow-md transform duration-300 ease-in-out ${
                                oiputtoggled ? "translate-x-4" : "translate-x-0"
                              }`}
                            />
                          </button>
                        </div>
                        <div className="mr-[21px]">
                          <span
                            style={{
                              fontSize: 15,
                              fontWeight: "400",
                              color: "#817E7E",
                            }}
                          >
                            Put
                          </span>
                        </div>
                      </div>
                    </th>
                  </tr>
                </thead>
                <tbody style={{ maxHeight: 500, overflowY: "auto" }}>
                  {optionchainwatchlistfilter().map((item, index) => {
                    let option_string = item[0].trading_symbol;
                    let position = option_string.lastIndexOf("P");
                    let value_after_p = option_string.substring(position + 1);
                    let strike_price = parseInt(value_after_p);
                    let exchange_token0 = item[0].exchange_token;
                    let exchange_token1 = item[1].exchange_token;
                    let ohlc_1 = item[1]?.ohlc;
                    let percent_c =
                      ((item[1]?.last_price - ohlc_1?.close) /
                        item[1]?.last_price) *
                      100;
                    let percentage_c = !Number.isFinite(percent_c)
                      ? 0.0
                      : percent_c;
                    // item?.last_traded_price_c === 0
                    //   ? 0.0
                    //   : (item?.change_price_c / item[1]?.last_price) * 100;

                    let ohlc_0 = item[0]?.ohlc;
                    let percent_p =
                      ((item[0]?.last_price - ohlc_0?.close) /
                        item[0]?.last_price) *
                      100;
                    let percentage_p = !Number.isFinite(percent_p)
                      ? 0.0
                      : percent_p;
                    // item?.last_traded_price_p === 0
                    //   ? 0.0
                    //   : (item?.change_price_p / item[0]?.last_price) * 100;

                    return (
                      <>
                        <tr
                          style={{ position: "relative" }}
                          ref={(el) => (rowRefs.current[index] = el)}
                        >
                          <td
                            onMouseOver={() => {
                              onMouseCallOverOptionChain(index);
                            }}
                            onMouseOut={() => {
                              onMouseCallOverOutOptionChain(index);
                            }}
                            className="px-4 py-2 relative border tablebodytd1"
                          >
                            {item.isCallHover && (
                              <div
                                className="border absolute flex flex-row"
                                style={{
                                  top: 20,
                                  right: 10,
                                  backgroundColor: "#ffffff",
                                  borderColor: "#f6f6f6",
                                  padding: 6,
                                  borderRadius: 4,
                                }}
                              >
                                <img
                                  onClick={() =>
                                    onOpenModal(
                                      "BUY",
                                      item[1].trading_symbol,
                                      "NFO",
                                      exchange_token1,
                                      item[1]?.last_price,
                                      item[1]
                                    )
                                  }
                                  src={BuyIcon}
                                  alt="buy icon"
                                  className="cursor-pointer"
                                  style={{
                                    width: 28,
                                    height: 28,
                                    marginRight: 7,
                                  }}
                                />
                                <img
                                  onClick={() =>
                                    onOpenModal(
                                      "SELL",
                                      item[1].trading_symbol,
                                      "NFO",
                                      exchange_token1,
                                      item[1]?.last_price,
                                      item[1]
                                    )
                                  }
                                  src={SellIcon}
                                  alt="sell icon"
                                  className="cursor-pointer"
                                  style={{
                                    width: 28,
                                    height: 28,
                                    marginRight: 7,
                                  }}
                                />

                                <img
                                  src={ChartIcon}
                                  alt="feature icon"
                                  className="cursor-pointer"
                                  onClick={() => {
                                    goToChartPage(
                                      "NFO",
                                      item[1].trading_symbol,
                                      item[1].exchange_token,
                                      item[1]?.last_price,
                                      item[1]
                                    );
                                  }}
                                  style={{
                                    width: 28,
                                    height: 28,
                                    marginRight: 7,
                                  }}
                                />
                              </div>
                            )}
                            <div className="flex flex-col">
                              <div className="flex flex-row items-center">
                                <img
                                  src={rupeeIcon}
                                  alt="Ruppe icon"
                                  className="w-[12px] h-[12px] mr-[3px]"
                                />
                                <span
                                  style={{
                                    color:
                                      item?.change_price_c <= 0
                                        ? "#DF2123"
                                        : "green",
                                  }}
                                >
                                  {item[1]?.last_price === null
                                    ? "0.00"
                                    : parseFloat(item[1]?.last_price)?.toFixed(
                                        2
                                      )}
                                  {/* {parseFloat(item[1]?.last_price)?.toFixed(2)} */}
                                </span>
                              </div>

                              <div
                                className="change"
                                style={{
                                  color:
                                    item?.change_price_c <= 0
                                      ? "#DF2123"
                                      : "green",
                                }}
                              >{`${
                                isNaN(item?.change_price_c)
                                  ? "0.00"
                                  : item?.change_price_c?.toFixed(2)
                              } ${
                                isNaN(percentage_c)
                                  ? "(0.00%)"
                                  : "(" + percentage_c?.toFixed(2) + "%)"
                              }`}</div>
                            </div>
                          </td>
                          <td className="px-4 py-2 border tablebodytd">
                            {strike_price}
                          </td>
                          <td
                            className="px-4 py-2  tablebodytd1"
                            onMouseOver={() => {
                              onMousePutOverOptionChain(index);
                            }}
                            onMouseOut={() => {
                              onMousePutOverOutOptionChain(index);
                            }}
                          >
                            {item.isPutHover && (
                              <div
                                className="border absolute flex flex-row"
                                style={{
                                  top: 20,
                                  right: 10,
                                  backgroundColor: "#ffffff",
                                  padding: 6,
                                  borderRadius: 4,
                                  borderColor: "#f6f6f6",
                                }}
                              >
                                <img
                                  onClick={() =>
                                    onOpenModal(
                                      "BUY",
                                      item[0].trading_symbol,
                                      "NFO",
                                      exchange_token0,
                                      item[0]?.last_price,
                                      item[0]
                                    )
                                  }
                                  src={BuyIcon}
                                  alt="buy icon"
                                  className="cursor-pointer"
                                  style={{
                                    width: 28,
                                    height: 28,
                                    marginRight: 7,
                                  }}
                                />
                                <img
                                  onClick={() => {
                                    onOpenModal(
                                      "SELL",
                                      item[0].trading_symbol,
                                      "NFO",
                                      exchange_token0,
                                      item[0]?.last_price,
                                      item[0]
                                    );
                                    // setModalData(fields, "SELL");
                                  }}
                                  src={SellIcon}
                                  alt="sell icon"
                                  className="cursor-pointer"
                                  style={{
                                    width: 28,
                                    height: 28,
                                    marginRight: 7,
                                  }}
                                />

                                <img
                                  src={ChartIcon}
                                  alt="feature icon"
                                  className="cursor-pointer"
                                  onClick={() => {
                                    goToChartPage(
                                      "NFO",
                                      item[0].trading_symbol,
                                      item[0].exchange_token,
                                      item[0]?.last_price,
                                      item[0]
                                    );
                                  }}
                                  style={{
                                    width: 28,
                                    height: 28,
                                    marginRight: 7,
                                  }}
                                />
                              </div>
                            )}
                            <div className="flex flex-col">
                              <div className="flex flex-row items-center">
                                <img
                                  src={rupeeIcon}
                                  alt="Ruppe icon"
                                  className="w-[12px] h-[12px] mr-[3px]"
                                />
                                <span
                                  style={{
                                    color:
                                      item.change_price_p <= 0
                                        ? "#DF2123"
                                        : "green",
                                  }}
                                >
                                  {item[0]?.last_price === null
                                    ? "0.00"
                                    : parseFloat(item[0].last_price)?.toFixed(
                                        2
                                      )}
                                  {/* {parseInt(item[0]?.last_price)?.toFixed(2)} */}
                                </span>
                              </div>
                              {/* <div style={{ color: "#39A245" }}>
                              +11.70 <span>(9.08%)</span>
                            </div> */}

                              <div
                                className="change"
                                style={{
                                  color:
                                    item?.change_price_p <= 0
                                      ? "#DF2123"
                                      : "green",
                                }}
                              >{`${
                                isNaN(item?.change_price_p)
                                  ? "0.0"
                                  : item?.change_price_p?.toFixed(2)
                              } ${
                                isNaN(percentage_p)
                                  ? "(0.00%)"
                                  : "(" + percentage_p?.toFixed(2) + "%)"
                              }`}</div>
                            </div>
                          </td>
                        </tr>

                        {ltpIndex === index && (
                          <tr>
                            <td>
                              <div
                                style={{
                                  width: "100%",
                                  // height:'100%',
                                  height: 2,
                                  // position: "relative",
                                  backgroundColor: "#000000",
                                  zIndex: 2,
                                }}
                              ></div>
                            </td>

                            <td>
                              <div
                                style={{
                                  // position: "absolute",
                                  backgroundColor: "#000000",
                                  color: "#ffffff",
                                  //width: "100%",
                                  padding: 4,
                                  // top: -8,
                                  // right: "-64%",

                                  zIndex: 1,
                                  fontSize: 12,
                                  textAlign: "center",
                                  borderRadius: 6,
                                }}
                              >
                                {headLivePrice.last_price===0?ltpValue:headLivePrice.last_price} | {optionChainType}
                              </div>
                            </td>

                            <td>
                              <div
                                style={{
                                  width: "100%",
                                  // height:'100%',
                                  height: 2,
                                  // position: "relative",
                                  backgroundColor: "#000000",
                                }}
                              ></div>
                            </td>
                          </tr>
                        )}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        );

      case "scriptsetting":
        return isScriptOrBased ? (
          <div
            style={{
              borderWidth: 0.5,
              paddingLeft: 16,
              paddingRight: 16,
              marginTop: 15,
              borderRadius: 4,
              borderColor: "#D9D9D9",
              paddingBottom: 23.16,
            }}
          >
            <h1 className="mt-[25px] w-full">Script Setting</h1>

            <form onSubmit={onscriptcall}>
              <div className="flex flex-row mt-[32px] w-full">
                {scriptSettingRadioOption.map((item, index) => {
                  return (
                    <label
                      key={index}
                      className="flex flex-row"
                      style={{
                        marginRight:
                          index !== scriptSettingRadioOption.length - 1
                            ? 10
                            : 0,
                      }}
                    >
                      <input
                        onClick={() => {
                          // console.log("script setting clicked :", index);
                          onScriptSettingListOption(item.name, index);
                        }}
                        type="radio"
                        value={item.name}
                        checked={selectedScriptSettingRadioOption === item.name}
                        onChange={onChangeScriptSetting}
                      />
                      <span className="ml-[4px]">{item.name}</span>
                    </label>
                  );
                })}
              </div>

              <div className="w-full mb-[16px] mt-[16px]">
                <label>
                  <p
                    className="mb-[8px]"
                    style={{ color: "#817e7e", fontSize: 14 }}
                  >
                    Instrument
                  </p>
                  <input
                    type="text"
                    onFocus={handleFocus}
                    value={instrumentName}
                    onChange={(e) => {
                      setInstrumentOpen(true);
                      setInstrumentName(e.target.value);
                      if (e.target.value === "") {
                        setSSField({});
                        setTooltipData([]);
                      }
                    }}
                    placeholder="Type in capitals"
                    style={{
                      padding: "5px",
                      borderRadius: 4,
                      width: "100%",
                      borderWidth: 0.5,
                      borderColor: "#D9D9D9",
                      marginBottom: 5,
                    }}
                    // value={name}
                    // onChange={(e) => setName(e.target.value)}
                  />

                  <div style={{ maxHeight: 150, overflowY: "auto" }}>
                    {instrumentOpen && (
                      <ul>
                        {instrumentLoader ? (
                          <SmallLoader />
                        ) : (
                          tooltipData.map((item, index) => {
                            let fields = item.fields;
                            // console.log("script setting wl :", item);
                            return (
                              <div
                                key={index}
                                style={{ cursor: "pointer", padding: 4 }}
                                onClick={() => {
                                  setInstrumentOpen(false);
                                  // console.log("ss fields : ", fields);

                                  setSSField(item);
                                }}
                              >
                                <li key={index}>{fields.trading_symbol}</li>
                                {index != tooltipData.length - 1 && (
                                  <div
                                    style={{
                                      borderWidth: 0.5,
                                      borderColor: "#E6E6E6",
                                      marginTop: 5,
                                    }}
                                  ></div>
                                )}
                              </div>
                            );
                          })
                        )}
                      </ul>
                    )}
                  </div>
                </label>
              </div>

              <div className="flex flex-row items-center justify-between w-full  mb-[16px]">
                <div style={{ width: "47.5%" }}>
                  <label>
                    <p
                      className="mb-[8px]"
                      style={{ color: "#817e7e", fontSize: 14 }}
                    >
                      Terminal symbol
                    </p>
                    <input
                      type="text"
                      placeholder="Terminal symbol"
                      value={ssField?.fields?.trading_symbol}
                      disabled={true}
                      style={{
                        padding: "5px",
                        borderRadius: 4,
                        width: "100%",
                        borderWidth: 0.5,
                        borderColor: "#D9D9D9",
                      }}
                      // value={name}
                      // onChange={(e) => setName(e.target.value)}
                    />
                  </label>
                </div>

                <div style={{ width: "47.5%" }}>
                  <label>
                    <p
                      className="mb-[8px]"
                      style={{ color: "#817e7e", fontSize: 14 }}
                    >
                      Datasource symbol
                    </p>
                    <input
                      type="text"
                      placeholder="Datasource symbol"
                      style={{
                        padding: "5px",
                        borderRadius: 4,
                        width: "100%",
                        borderWidth: 0.5,
                        borderColor: "#D9D9D9",
                      }}
                      value={sname}
                      onChange={(e) => setSname(e.target.value)}
                    />
                  </label>
                </div>
              </div>

              <div className="flex flex-row items-center justify-between  mb-[16px]">
                <div className="flex  flex-col w-full mr-[16.5px]">
                  <label
                    htmlFor="product"
                    className="mb-[5px]"
                    style={{ color: "#817e7e", fontSize: 14 }}
                  >
                    Product type
                  </label>
                  <select
                    id="options"
                    value={selectedScrProductType}
                    onChange={(e) => setSelectedScrProductType(e.target.value)}
                    style={{
                      padding: "5px",
                      borderRadius: 4,
                      width: "100%",
                      borderWidth: 0.5,
                      borderColor: "#D9D9D9",
                      color: "#817e7e",
                    }}
                  >
                    <option value="DELIVERY">Delivery</option>
                    <option value="MIS">MIS</option>
                  </select>
                </div>

                <div className="flex  flex-col w-full">
                  <label
                    htmlFor="product"
                    className="mb-[5px]"
                    style={{ color: "#817e7e", fontSize: 14 }}
                  >
                    Order type
                  </label>

                  <select
                    id="options"
                    value={selectedScrOrderType}
                    onChange={(e) => setSelectedScrOrderType(e.target.value)}
                    style={{
                      padding: "5px",
                      borderRadius: 4,
                      // width: 129,
                      borderWidth: 0.5,
                      borderColor: "#D9D9D9",
                      color: "#817e7e",
                    }}
                  >
                    <option value="MARKET">Market</option>
                    <option value="LIMIT">Limit</option>
                  </select>
                </div>
              </div>

              <div className="flex flex-row items-center justify-between mb-[16px]">
                <div style={{ width: "47.5%" }}>
                  <label>
                    <p
                      className="mb-[8px]"
                      style={{ color: "#817e7e", fontSize: 14 }}
                    >
                      Quantity
                    </p>
                    <input
                      type="number"
                      min="0"
                      placeholder="Quantity"
                      style={{
                        padding: "5px",
                        borderRadius: 4,
                        // width: 129,
                        width: "100%",
                        borderWidth: 0.5,
                        borderColor: "#D9D9D9",
                      }}
                      value={scrQty}
                      onChange={(e) => setScrQty(e.target.value)}
                    />
                  </label>
                </div>

                <div style={{ width: "47.5%" }}>
                  <label>
                    <p
                      className="mb-[8px]"
                      style={{ color: "#817e7e", fontSize: 14 }}
                    >
                      Lot Size
                    </p>
                    <input
                      type="text"
                      placeholder="Lot Size"
                      disabled={true}
                      value={ssField?.fields?.lot_size}
                      style={{
                        padding: "5px",
                        borderRadius: 4,
                        // width: 129,
                        width: "100%",
                        borderWidth: 0.5,
                        borderColor: "#D9D9D9",
                      }}
                      // value={name}
                      // onChange={(e) => setName(e.target.value)}
                    />
                  </label>
                </div>
              </div>

              <div className="flex flex-row items-center justify-between mb-[16px]">
                <div className="targetApp" style={{ width: "47.5%" }}>
                  <p className="targeth1">Stop loss</p>
                  <div className="tcontainer">
                    <a
                      className={`tunit-button ${
                        unit1 === "%" ? "active" : ""
                      }`}
                      onClick={() => handleUnitChange1("%")}
                      style={{
                        borderBottomRightRadius: "0px",
                        borderTopRightRadius: "0px",
                      }}
                    >
                      %
                    </a>
                    <input
                      type="text"
                      value={value1}
                      onChange={handleInputChange1}
                      className="tvalue-input"
                    />
                    <a
                      className={`tunit-button ${
                        unit1 === "Pt" ? "active" : ""
                      }`}
                      onClick={() => handleUnitChange1("Pt")}
                      style={{
                        borderBottomLeftRadius: "0px",
                        borderTopLeftRadius: "0px",
                      }}
                    >
                      Pt
                    </a>
                  </div>
                </div>

                <div
                  style={{ width: "47.5%" }}
                  className="flex  flex-col ml-[16.5px]"
                >
                  <label
                    htmlFor="product"
                    className="mb-[5px]"
                    style={{ color: "#817e7e", fontSize: 14 }}
                  >
                    Stopless type
                  </label>
                  <select
                    id="options"
                    value={selectedScrStopLossType}
                    onChange={(e) => setSelectedScrStopLossType(e.target.value)}
                    style={{
                      padding: "5px",
                      borderRadius: 4,
                      width: "100%",
                      borderWidth: 0.5,
                      borderColor: "#D9D9D9",
                      color: "#817e7e",
                    }}
                  >
                    <option value="FIXED">FIXED</option>
                    <option value="STOP TRAILING">STEP TRAILING</option>
                    <option value="PNL TRAILING">PNL TRAILING</option>
                    {/* <option value="TRAILING">TRAILING</option> */}
                  </select>
                </div>
              </div>

              {/* <div className="flex flex-row items-center justify-between mb-[24px]">
                <div className="targetApp" style={{ width: "47.5%" }}>
                  <p className="targeth1">Target 1</p>
                  <div className="tcontainer">
                    <a
                      className={`tunit-button ${
                        unit1 === "%" ? "active" : ""
                      }`}
                      onClick={() => handleUnitChange1("%")}
                      style={{
                        borderBottomRightRadius: "0px",
                        borderTopRightRadius: "0px",
                      }}
                    >
                      %
                    </a>
                    <input
                      type="text"
                      value={value1}
                      onChange={handleInputChange1}
                      className="tvalue-input"
                    />
                    <a
                      className={`tunit-button ${
                        unit1 === "Pt" ? "active" : ""
                      }`}
                      onClick={() => handleUnitChange1("Pt")}
                      style={{
                        borderBottomLeftRadius: "0px",
                        borderTopLeftRadius: "0px",
                      }}
                    >
                      Pt
                    </a>
                  </div>
                </div>

                <div className="targetApp" style={{ width: "47.5%" }}>
                  <p className="targeth1">Target 2</p>
                  <div className="tcontainer">
                    <a
                      className={`tunit-button ${
                        unit2 === "%" ? "active" : ""
                      }`}
                      onClick={() => handleUnitChange2("%")}
                      style={{
                        borderBottomRightRadius: "0px",
                        borderTopRightRadius: "0px",
                      }}
                    >
                      %
                    </a>
                    <input
                      type="text"
                      value={value2}
                      onChange={handleInputChange2}
                      className="tvalue-input"
                    />
                    <a
                      className={`tunit-button ${
                        unit2 === "Pt" ? "active" : ""
                      }`}
                      onClick={() => handleUnitChange2("Pt")}
                      style={{
                        borderBottomLeftRadius: "0px",
                        borderTopLeftRadius: "0px",
                      }}
                    >
                      Pt
                    </a>
                  </div>
                </div>
              </div> */}

              {/* <div className="flex flex-row items-center justify-between mb-[24px]">
                <div className="targetApp" style={{ width: "47.5%" }}>
                  <p className="targeth1">Target 3</p>
                  <div className="tcontainer">
                    <a
                      className={`tunit-button ${
                        unit3 === "%" ? "active" : ""
                      }`}
                      onClick={() => handleUnitChange3("%")}
                      style={{
                        borderBottomRightRadius: "0px",
                        borderTopRightRadius: "0px",
                      }}
                    >
                      %
                    </a>
                    <input
                      type="text"
                      value={value3}
                      onChange={handleInputChange3}
                      className="tvalue-input"
                    />
                    <a
                      className={`tunit-button ${
                        unit3 === "Pt" ? "active" : ""
                      }`}
                      onClick={() => handleUnitChange3("Pt")}
                      style={{
                        borderBottomLeftRadius: "0px",
                        borderTopLeftRadius: "0px",
                      }}
                    >
                      Pt
                    </a>
                  </div>
                </div>

                <div style={{ width: "47.5%" }}>
                  <label>
                    <p
                      className="mb-[8px]"
                      style={{ color: "#817e7e", fontSize: 14 }}
                    >
                      Target 3 Qty
                    </p>
                    <input
                      type="text"
                      placeholder="Target 3 Qty"
                      style={{
                        padding: "5px",
                        borderRadius: 4,
                        // width: 129,
                        width: "100%",
                        borderWidth: 0.5,
                        borderColor: "#D9D9D9",
                      }}
                      // value={name}
                      // onChange={(e) => setName(e.target.value)}
                    />
                  </label>
                </div>
              </div> */}

              {/* <div className="flex flex-row items-center justify-between mb-[24px]">
                <div style={{ width: "47.5%" }}>
                  <label>
                    <p
                      className="mb-[8px]"
                      style={{ color: "#817e7e", fontSize: 14 }}
                    >
                      Target 2 Qty
                    </p>
                    <input
                      type="text"
                      placeholder="Target 2 Qty"
                      style={{
                        padding: "5px",
                        borderRadius: 4,
                        // width: 129,
                        width: "100%",
                        borderWidth: 0.5,
                        borderColor: "#D9D9D9",
                      }}
                      // value={name}
                      // onChange={(e) => setName(e.target.value)}
                    />
                  </label>
                </div>

                <div style={{ width: "47.5%" }}>
                  <label>
                    <p
                      className="mb-[8px]"
                      style={{ color: "#817e7e", fontSize: 14 }}
                    >
                      Target 3 Qty
                    </p>
                    <input
                      type="text"
                      placeholder="Target 3 Qty"
                      style={{
                        padding: "5px",
                        borderRadius: 4,
                        // width: 129,
                        width: "100%",
                        borderWidth: 0.5,
                        borderColor: "#D9D9D9",
                      }}
                      // value={name}
                      // onChange={(e) => setName(e.target.value)}
                    />
                  </label>
                </div>
              </div> */}

              <div className="flex flex-row justify-end">
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#D4EDDA",
                    paddingLeft: 28,
                    paddingRight: 28,
                    paddingTop: 9,
                    paddingBottom: 9,
                    borderRadius: 4,
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        ) : (
          <div
            style={{
              borderWidth: 0.5,
              paddingLeft: 16,
              paddingRight: 16,
              marginTop: 15,
              borderRadius: 4,
              borderColor: "#D9D9D9",
              paddingBottom: 23.16,
            }}
          >
            <h1 className="mt-[25px] mb-4 w-full font-medium">Based On</h1>

            <form onSubmit={basedonscript}>
              <div className="flex mb-4 flex-col ">
                <label
                  htmlFor="product"
                  className="mb-2"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  Select Index
                </label>
                <select
                  id="options"
                  value={selectIdx}
                  onChange={(e) => setSelectIdx(e.target.value)}
                  style={{
                    padding: "5px",
                    borderRadius: 4,
                    width: "100%",
                    borderWidth: 0.5,
                    fontSize: 11,

                    borderColor: "#D9D9D9",
                    color: "#817e7e",
                  }}
                >
                  {/* nifty, bank nifty, finnifty */}

                  <option value="NIFTY">NIFTY </option>
                  <option value="BANKNIFTY">BANK NIFTY</option>
                  <option value="FINNIFTY">FINNIFTY</option>
                  {/* <option value="TRAILING">TRAILING</option> */}
                </select>
              </div>

              <div className="flex mb-4  flex-col ">
                <label
                  htmlFor="product"
                  className="mb-2"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  Select script
                </label>
                <div className="flex items-center justify-between">
                  <select
                    id="options"
                    value={scrip}
                    onChange={(e) => setScrip(e.target.value)}
                    style={{
                      padding: "5px",
                      borderRadius: 4,
                      width: "49%",
                      borderWidth: 0.5,
                      fontSize: 11,

                      borderColor: "#D9D9D9",
                      color: "#817e7e",
                    }}
                  >
                    {scripts.map((scr, index) => {
                      //console.log("scr : ", scr);

                      let token_id = scr.token_id;
                      return (
                        <option
                          key={index}
                          value={scr.id}
                        >{`${token_id?.name} / ${scr?.datasource_symbol}`}</option>
                      );
                    })}
                  </select>
                  <select
                    id="options"
                    value={contract_type}
                    onChange={(e) => setContract_type(e.target.value)}
                    style={{
                      padding: "5px",
                      borderRadius: 4,
                      width: "49%",
                      borderWidth: 0.5,
                      fontSize: 11,
                      borderColor: "#D9D9D9",
                      color: "#817e7e",
                    }}
                  >
                    {/* Nearest week contract, 
2nd week contract, 
last month/ last week contract */}

                    <option value="1">Nearest week contract</option>
                    <option value="2">2nd week contract</option>
                    <option value="3">3rd week contract</option>
                    <option value="4">last month/ last week contract</option>
                    {/* <option value="TRAILING">TRAILING</option> */}
                  </select>
                </div>
              </div>

              <div className="flex mb-4  flex-col ">
                <label
                  htmlFor="product"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  When Buy
                </label>
                <div className="flex items-center justify-between">
                  <select
                    id="options"
                    className="w-1/6"
                    value={whenbuycepe}
                    onChange={(e) => setwhenbuycepe(e.target.value)}
                    style={{
                      padding: "5px",
                      borderRadius: 4,
                      // width: "49%",
                      fontSize: 11,
                      borderWidth: 0.5,
                      borderColor: "#D9D9D9",
                      color: "#817e7e",
                    }}
                  >
                    <option value="CE">CE</option>
                    <option value="PE">PE</option>
                  </select>

                  <select
                    id="options"
                    //className="w-1/6"
                    value={whenbuyact}
                    onChange={(e) => setwhenbuyact(e.target.value)}
                    style={{
                      padding: "5px",
                      borderRadius: 4,
                      // width: "49%",
                      fontSize: 11,
                      borderWidth: 0.5,
                      borderColor: "#D9D9D9",
                      color: "#817e7e",
                    }}
                  >
                    <option value="BUY">BUY</option>
                    <option value="SELL">SELL</option>
                  </select>

                  <select
                    id="options"
                    // className="w-1/6"
                    value={whenbuyatm}
                    onChange={(e) => setwhenbuyatm(e.target.value)}
                    style={{
                      padding: "5px",
                      borderRadius: 4,
                      // width: "49%",
                      fontSize: 11,

                      borderWidth: 0.5,
                      borderColor: "#D9D9D9",
                      color: "#817e7e",
                    }}
                  >
                    <option value="-100">ATM-100</option>
                    <option value="-200">ATM-200</option>
                    <option value="-300">ATM-300</option>
                    <option value="-400">ATM-400</option>
                    <option value="-500">ATM-500</option>
                    <option value="-600">ATM-600</option>
                    <option value="-700">ATM-700</option>
                    <option value="-800">ATM-800</option>
                    <option value="-900">ATM-900</option>
                    <option value="100">ATM 100</option>
                    <option value="200">ATM 200</option>
                    <option value="300">ATM 300</option>
                    <option value="400">ATM 400</option>
                    <option value="500">ATM 500</option>
                    <option value="600">ATM 600</option>
                    <option value="700">ATM 700</option>
                    <option value="800">ATM 800</option>
                    <option value="900">ATM 900</option>
                    <option value="0">ATM</option>
                  </select>

                  <div className="w-1/6 h-full">
                    <input
                      type="text"
                      placeholder="QTY"
                      className="w-full"
                      style={{
                        padding: "6px",
                        borderRadius: 4,
                        borderWidth: 0.5,
                        fontSize: 11,

                        borderColor: "#D9D9D9",
                      }}
                      value={whenbuyqty}
                      onChange={(e) => setwhenbuyqty(e.target.value)}
                    />
                  </div>
                  <div className="w-1/6 h-full">
                    <input
                      type="text"
                      className="w-full"
                      placeholder="range : eg 100-250"
                      style={{
                        padding: "6px",
                        borderRadius: 4,
                        fontSize: 11,

                        borderWidth: 0.5,
                        borderColor: "#D9D9D9",
                      }}
                      value={whenbuyrange}
                      onChange={(e) => setwhenbuyrange(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="flex mb-4  flex-col ">
                <label
                  htmlFor="product"
                  className="mb-[5px]"
                  style={{ color: "#817e7e", fontSize: 14 }}
                >
                  When Sell
                </label>
                <div className="flex items-center justify-between">
                  <select
                    id="options"
                    className="w-1/6"
                    value={whensellcepe}
                    onChange={(e) => setwhensellcepe(e.target.value)}
                    style={{
                      padding: "5px",
                      borderRadius: 4,
                      // width: "49%",
                      fontSize: 11,
                      borderWidth: 0.5,
                      borderColor: "#D9D9D9",
                      color: "#817e7e",
                    }}
                  >
                    <option value="CE">CE</option>
                    <option value="PE">PE</option>
                  </select>

                  <select
                    id="options"
                    //className="w-1/6"
                    value={whensellact}
                    onChange={(e) => setwhensellact(e.target.value)}
                    style={{
                      padding: "5px",
                      borderRadius: 4,
                      // width: "49%",
                      fontSize: 11,
                      borderWidth: 0.5,
                      borderColor: "#D9D9D9",
                      color: "#817e7e",
                    }}
                  >
                    <option value="BUY">BUY</option>
                    <option value="SELL">SELL</option>
                  </select>

                  <select
                    id="options"
                    // className="w-1/6"
                    value={whensellatm}
                    onChange={(e) => setwhensellatm(e.target.value)}
                    style={{
                      padding: "5px",
                      borderRadius: 4,
                      // width: "49%",
                      fontSize: 11,

                      borderWidth: 0.5,
                      borderColor: "#D9D9D9",
                      color: "#817e7e",
                    }}
                  >
                    <option value="-100">ATM-100</option>
                    <option value="-200">ATM-200</option>
                    <option value="-300">ATM-300</option>
                    <option value="-400">ATM-400</option>
                    <option value="-500">ATM-500</option>
                    <option value="-600">ATM-600</option>
                    <option value="-700">ATM-700</option>
                    <option value="-800">ATM-800</option>
                    <option value="-900">ATM-900</option>
                    <option value="100">ATM 100</option>
                    <option value="200">ATM 200</option>
                    <option value="300">ATM 300</option>
                    <option value="400">ATM 400</option>
                    <option value="500">ATM 500</option>
                    <option value="600">ATM 600</option>
                    <option value="700">ATM 700</option>
                    <option value="800">ATM 800</option>
                    <option value="900">ATM 900</option>
                    <option value="0">ATM</option>
                  </select>

                  <div className="w-1/6 h-full">
                    <input
                      type="text"
                      placeholder="QTY"
                      className="w-full"
                      style={{
                        padding: "6px",
                        borderRadius: 4,
                        borderWidth: 0.5,
                        fontSize: 11,

                        borderColor: "#D9D9D9",
                      }}
                      value={whensellqty}
                      onChange={(e) => setwhensellqty(e.target.value)}
                    />
                  </div>
                  <div className="w-1/6 h-full">
                    <input
                      type="text"
                      className="w-full"
                      placeholder="range : eg 100-250"
                      style={{
                        padding: "6px",
                        borderRadius: 4,
                        fontSize: 11,

                        borderWidth: 0.5,
                        borderColor: "#D9D9D9",
                      }}
                      value={whensellrange}
                      onChange={(e) => setwhensellrange(e.target.value)}
                    />
                  </div>
                </div>
              </div>

              <div className="flex flex-row ">
                <button
                  type="submit"
                  style={{
                    backgroundColor: "#D4EDDA",
                    paddingLeft: 28,
                    paddingRight: 28,
                    paddingTop: 9,
                    paddingBottom: 9,
                    borderRadius: 4,
                  }}
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        );

      default:
        return (
          <div className="flex flex-col">
            <div
              className="flex flex-row"
              style={{ marginTop: 12, marginBottom: 8 }}
            >
              {watchlistOption.map((item, index) => {
                return (
                  <div
                    key={index}
                    onClick={() => onClickWatchListOption(item, index)}
                    className="text-gray-500"
                    style={{
                      backgroundColor: item.isSelected ? "#D4EDDA" : "#ffffff",
                      width: "48px",
                      // height: "20px",
                      marginLeft: "22px",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      paddingHorizontal: 12,
                      paddingVertical: 10,
                      borderRadius: 4,
                    }}
                  >
                    {/* <a style={{ fontSize: 12 }}>{item.name}</a> */}
                    <button style={{ fontSize: 12 }}>{item.name}</button>
                  </div>
                );
              })}
            </div>

            <div>
              <div
                style={{
                  marginBottom: searchItem.length !== 0 ? 0 : 8,
                  paddingVertical: 10,
                  borderRadius: 6,
                  borderWidth: 1,
                  borderColor: "#E6E6E6",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    paddingVertical: 10,
                    alignItems: "center",
                    marginLeft: 14,
                    marginTop: 10,
                    marginBottom: 10,
                  }}
                  ref={inputRef}
                >
                  <img
                    src={searchIcon}
                    alt="search icon"
                    style={{ width: 12, height: 12, marginRight: 8 }}
                  />
                  <input
                    type="text"
                    value={searchItem}
                    // ref={inputRef}

                    onChange={(e) => onSearch(e)}
                    placeholder="Search for companies to invest or trade"
                    style={{ fontSize: 12 }}
                    className="bg-gray-100 outline-none w-full text-gray-500"
                  />
                  {!isSearchActive && (
                    <img
                      src={closeIcon}
                      onClick={() => {
                        setIsSearchActive(true);
                        setSearchItem("");
                      }}
                      alt="close icon"
                      style={{ width: 12, height: 12, marginRight: 12 }}
                    />
                  )}
                </div>
              </div>
            </div>

            <div
              className="relative border"
              style={{
                borderRadius: 6,
                borderWidth: 1,
                borderColor: "#E6E6E6",
                height: isDemoPage ? "73vh" : "65vh",
                // height: "calc(100vh-200px)",
              }}
            >
              {isSearchActive ? (
                <div
                  style={{
                    marginBottom: "8px ",
                    paddingVertical: 10,
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      paddingVertical: 10,
                      alignItems: "center",
                      marginLeft: 14,
                      marginTop: 10,
                      marginBottom: 10,
                    }}
                  >
                    <img
                      src={menuIcon}
                      alt="menu icon"
                      style={{ width: 14, height: 14, marginRight: 8 }}
                    />
                    {showEdit && (
                      <div className="bg-gray-100 outline-none w-full text-gray-500">
                        {myWatchList[segment]?.name}
                      </div>
                    )}

                    {showEdit ? (
                      <img
                        src={editIcon}
                        onClick={() => setShowEdit(false)}
                        alt="edit icon"
                        style={{
                          width: 12,
                          height: 12,
                          marginRight: 12,
                          cursor: "pointer",
                        }}
                      />
                    ) : (
                      <form onSubmit={changeWatchListName}>
                        <div className="flex items-center  border-teal-500 py-2">
                          <input
                            className="text-gray-700 py-1 px-2 w-[60%] mr-1"
                            type="text"
                            value={renamedSegmentName}
                            onChange={(e) =>
                              setRenamedSegmentName(e.target.value)
                            }
                            placeholder="Enter WatchList name"
                          />
                          {renameLoader ? (
                            <SmallLoader />
                          ) : (
                            <button
                              className=" bg-teal-500  text-sm  text-white py-1 px-2 rounded"
                              type="submit"
                            >
                              <TiTick className="w-[32px]" />
                            </button>
                          )}
                          <button
                            className=" text-teal-500  text-sm py-1 px-2 rounded"
                            type="button"
                            onClick={() => setShowEdit(true)}
                          >
                            <MdOutlineClose />
                          </button>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              ) : !isSearchActive ? (
                <div className="mb-[16px] mt-[16px] ml-[14px]">
                  <span style={{ fontSize: 14, color: "#363636" }}>
                    Are you looking for :
                  </span>
                </div>
              ) : (
                <div className="mb-[16px] mt-[16px] ml-[14px]"></div>
              )}

              <div
                // ref={dropdownRef}
                style={{
                  display: "flex",
                  flexDirection: "column",
                  //marginBottom: 52,
                  maxHeight: isDemoPage ? "78.5%" : "75.5%",
                  // isDemoPage ? "73vh" : "65vh"
                  overflowY: "auto",
                }}
              >
                {!isSearchActive ? (
                  <div ref={dropdownRef}>
                    {searchingWatchLists.map((item, index) => {
                      const fields = item.fields;
                      // console.log("fields : ", fields);

                      return (
                        <div
                          key={index}
                          className="relative watchlist-item"
                          style={{ paddingLeft: 14 }}
                          onMouseOver={() =>
                            mouseOverOnSearchingLists(item, index)
                          }
                          onMouseOut={() =>
                            mouseOutOnSearchingLists(item, index)
                          }
                        >
                          {item.isHover && !item.isAdded && (
                            <div
                              className="absolute flex flex-row"
                              style={{
                                top: 15,
                                right: 12,
                              }}
                            >
                              <img
                                src={ChartIcon}
                                alt=""
                                style={{
                                  width: 28,
                                  height: 28,
                                  marginRight: 7,
                                  cursor: "Pointer",
                                }}
                              />
                              <img
                                src={AddIcon}
                                onClick={() =>
                                  addWatchListCall(item, index, fields)
                                }
                                alt=""
                                style={{
                                  width: 28,
                                  height: 28,
                                  marginRight: 7,
                                  cursor: "Pointer",
                                }}
                              />
                            </div>
                          )}

                          {item.isAdded && (
                            <div
                              className="absolute flex flex-row"
                              style={{ top: 15, right: 12 }}
                            >
                              <img
                                src={AddedIcon}
                                alt=""
                                style={{
                                  width: 16,
                                  height: 16,
                                  marginRight: 7,
                                }}
                              />
                            </div>
                          )}

                          <div className="flex flex-row items-center">
                            <div
                              style={{
                                backgroundColor: instrumentTypeBackgroundColor(
                                  fields.instrument_type
                                ),
                                borderRadius: 2,
                                fontSize: 10,
                              }}
                              className="mr-[8px] w-[36px] h-[20px] flex flex-row items-center justify-center"
                            >
                              <div
                                style={{
                                  fontSize: 10,
                                }}
                              >
                                {instrumentType(fields)}
                              </div>
                            </div>
                            <span
                              className="mr-[8px]"
                              style={{ fontSize: 12, fontWeight: "700" }}
                            >
                              {fields.trading_symbol}
                            </span>
                            <span
                              style={{
                                fontSize: 10,
                                fontWeight: "400",

                                color: "#817E7E",
                              }}
                            >
                              {fields.exchange}
                            </span>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                ) : (
                  watchingList?.map((it, index) => {
                    let item = it[1];
                    // console.log(item);
                    let fields = item?.fields;
                    let change_price = item?.price_change;
                    let ohlc = item?.ohlc;
                    let percent =
                      // item?.last_price === 0
                      //   ? 0.0
                      //   : (change_price / item?.last_price) * 100;
                      ((item?.last_price - ohlc?.close) / item?.last_price) *
                      100;
                    //console.log("infi : ", percent);
                    let percentage = !Number.isFinite(percent) ? 0.0 : percent;

                    return (
                      <div
                        key={index}
                        className="watchlist-item relative"
                        onMouseOver={() => mouseOverOnWatchLists(item, index)}
                        onMouseOut={() => mouseOutOnWatchLists(item, index)}
                      >
                        {item.isHover && (
                          <div
                            className="border absolute flex flex-row"
                            style={{
                              top: 18,
                              right: 12,
                              borderColor: "#f2f2f2",
                              backgroundColor: "#ffffff",
                              padding: 6,
                              borderRadius: 4,
                            }}
                          >
                            <button>
                              <img
                                onClick={() => {
                                  setIsToggled(true);
                                  setModalData(fields, "BUY", item?.last_price);
                                }}
                                src={BuyIcon}
                                alt="buy icon"
                                className="cursor-pointer"
                                style={{
                                  width: 28,
                                  height: 28,
                                  marginRight: 7,
                                }}
                              />
                            </button>

                            <button>
                              <img
                                onClick={() => {
                                  setIsToggled(true);
                                  setModalData(
                                    fields,
                                    "SELL",
                                    item?.last_price
                                  );
                                }}
                                src={SellIcon}
                                alt="sell icon"
                                className="cursor-pointer"
                                style={{
                                  width: 28,
                                  height: 28,
                                  marginRight: 7,
                                }}
                              />
                            </button>

                            <button
                              onClick={() => onDeleteWatchListItem(it[0])}
                            >
                              <img
                                src={DeleteIcon}
                                alt="delete icon"
                                className="cursor-pointer"
                                style={{
                                  width: 28,
                                  height: 28,
                                  marginRight: 7,
                                }}
                              />
                            </button>

                            <button>
                              <img
                                src={ChartIcon}
                                alt="feature icon"
                                className="cursor-pointer"
                                onClick={() => {
                                  goToChartPage(
                                    fields.exchange,
                                    fields.trading_symbol,
                                    fields.instrument_token,
                                    item.last_price,
                                    fields
                                  );
                                }}
                                style={{
                                  width: 28,
                                  height: 28,
                                  marginRight: 7,
                                }}
                              />
                            </button>
                          </div>
                        )}

                        <div className="left">
                          <div>{fields?.trading_symbol}</div>
                          <div className="subtext">
                            {fields?.instrument_type}
                          </div>
                        </div>
                        <div
                          className="flex"
                          style={{
                            alignItems: "center",
                            textAlign: "right",
                            color: item.price_change <= 0 ? "#DF2123" : "green",
                          }}
                        >
                          <div>
                            <div className="price">
                              {item?.last_price === 0.0
                                ? " "
                                : item?.last_price}
                            </div>
                            <div className="change">{`${
                              item?.price_change === 0.0
                                ? " "
                                : item?.price_change?.toFixed(2)
                            } ${
                              // isNaN(percentage)
                              //   ? "0.0"
                              //   : percentage.toFixed(2)
                              percentage === 0.0
                                ? " "
                                : "(" + percentage?.toFixed(2) + "%)"
                            }`}</div>
                          </div>

                          {item.price_change !== 0.0 && (
                            <img
                              src={
                                item.price_change <= 0 ? downIcon : vectorIcon
                              }
                              alt="up icon"
                              style={{
                                width: 8,
                                height: 5.16,
                                marginLeft: 6,
                                marginRight: 12,
                                // tintColor:
                                //   changePrice <= 0 ? "#DF2123" : "green",
                              }}
                            />
                          )}
                        </div>
                      </div>
                    );
                  })
                )}
              </div>

              <div
                className="flex flex-row mt-[44px] absolute"
                style={{ left: 12, bottom: 8 }}
              >
                {pagination.map((item, index) => (
                  <button
                    key={index}
                    onClick={() => onFilterPagination(item, index)}
                    className={item.isSelected ? "active" : ""}
                    style={{
                      borderWidth: 1,
                      borderColor: "#E6E6E6",
                      width: 32,
                      height: 32,
                      backgroundColor: item.isSelected ? "#D4EDDA" : "#FFFFFF",
                      marginRight: index !== pagination.length ? 8 : 0,
                      borderRadius: 4,
                    }}
                  >
                    {item?.name}
                  </button>
                ))}
              </div>
            </div>

            <BuyAndSellModal isOpen={bopen} setIsOpen={setBopen} />
          </div>
        );
    }
  };

  return renderLeftSideContent();
}

export default WatchList;
